'use client'
import { IconType } from "react-icons"

const {
  FaHome,
  FaUser,
  FaStar,
  FaTruckMoving,
  FaCheck,
  FaRegHeart,
  FaSignInAlt,
  FaExpeditedssl,
  FaRegIdCard,
  FaRegCopy,
  FaArrowUp,
  FaCaretRight,
  FaSolarPanel,
  FaSignOutAlt,
  FaBars,
  FaExternalLinkSquareAlt,
  FaSearch,
  FaEye,
  FaChevronDown,
  FaSearchLocation,
  FaLandmark,
  FaFacebook,
  FaTelegram,
  FaInstagram,
  FaChevronLeft,
  FaChevronRight,
  FaSort,
  FaHeart,
  FaCogs,
  FaCalendarAlt,
  FaRegFileImage,
  FaFolderOpen,
  FaUpload,
  FaTrashAlt
} = require('react-icons/fa')

const { 
  FaMoneyBillWheat,
  FaHouseChimneyMedical,
  FaMagnifyingGlassLocation,
  FaUserPlus,
  FaLocationDot,
  FaFirefox,
  FaPeopleGroup,
  FaXmark,
  FaBolt,
  FaImages,
  FaFilePen
} = require('react-icons/fa6')

const DynamicIcon: React.FC<IDynamicIcon> = ({ icon, className, title, ...rest }) => {
  const icons: { [key: string]: IconType } = {
    'FaHome': FaHome,
    'FaUser': FaUser,
    'FaStar': FaStar,
    'FaTruckMoving': FaTruckMoving,
    'FaCheck': FaCheck,
    'FaRegHeart': FaRegHeart,
    'FaSignInAlt': FaSignInAlt,
    'FaExpeditedssl': FaExpeditedssl, 
    'FaRegIdCard': FaRegIdCard, 
    'FaRegCopy': FaRegCopy,  
    'FaArrowUp': FaArrowUp,
	  'FaCaretRight':FaCaretRight,
    'FaSolarPanel': FaSolarPanel,
    'FaHouseChimneyMedical': FaHouseChimneyMedical,   
    'FaSignOutAlt': FaSignOutAlt,
    'FaMagnifyingGlassLocation':FaMagnifyingGlassLocation,
    'FaBars': FaBars,
    'FaUserPlus': FaUserPlus,
    'FaMoneyBillWheat': FaMoneyBillWheat,
    'FaFirefox':FaFirefox,
    'FaSearch': FaSearch,   
    'FaLocationDot': FaLocationDot,
    'FaExternalLinkSquareAlt': FaExternalLinkSquareAlt,
    'FaPeopleGroup': FaPeopleGroup, 
    'FaEye': FaEye,
    'FaChevronDown': FaChevronDown,
    'FaSearchLocation': FaSearchLocation,
    'FaLandmark': FaLandmark,
    'FaFacebook': FaFacebook,
    'FaTelegram': FaTelegram,
    'FaInstagram': FaInstagram,
    'FaXmark': FaXmark,
    'FaChevronLeft': FaChevronLeft,
    'FaChevronRight': FaChevronRight,
    'FaSort': FaSort,
    'FaBolt': FaBolt,
    'FaHeart': FaHeart,
    'FaCogs': FaCogs,
    'FaCalendarAlt': FaCalendarAlt,
    'FaImages': FaImages,
    'FaFilePen': FaFilePen,
    'FaRegFileImage': FaRegFileImage,
    'FaFolderOpen': FaFolderOpen,
    'FaUpload': FaUpload,
    'FaTrashAlt': FaTrashAlt
  }

  // Check if the requested icon exists
  const Icon = icons[icon]

  if (!Icon) {
    console.error(`Icon "${icon}" does not exist in react-icons`)
    return null
  }

  // Render the icon
  return <Icon 
      className={className} 
      title={title} 
      {...rest} 
    />
};

export default DynamicIcon
